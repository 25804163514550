function Header() {
  return (
    <header className="App-header">
      <img
        src="../images/fcLogo.png"
        alt="First Class Home Mortgage Logo"
        className="topLogo"
      />
    </header>
  );
}

export default Header;
